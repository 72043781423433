import React from 'react'
import { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import DatePicker, { registerLocale } from 'react-datepicker'
import {
  FormControl,
  Tooltip,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Skeleton from '@material-ui/lab/Skeleton'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Cart } from '../../typings/base'
import Button from '../Button/Button'
import ShippingInfos from '../ShippingInfos/ShippingInfos'
import useStyles from './DateRangeForm.style'
import Loader from '../Loader/Loader'
import { scrollToDiv } from '../../utils/utils'
import { enableHalfDay, isSubscription } from '../../utils/constants'
import CMSText, { isPrismicText, prismicText } from '../Global/CMSText'
import {
  getPromotions,
  setCurrentPromotion,
} from '../../redux/actions/promotions'
import { ERequestUrl } from '../../redux/helpers/requests'
import { isFlex } from '../../utils/constants'

//Add locale translations of date picker
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import es from 'date-fns/locale/es'
import nl from 'date-fns/locale/nl'
import { useCart } from '../../hooks/useCart'

registerLocale('fr', fr)
registerLocale('de', de)
registerLocale('es', es)
registerLocale('nl', nl)

type Tprops = {
  isLanding?: boolean
  text?: any
  textFunnel?: any
  t: any
  theme?: any
  parameters?: any
  getPromotions: () => any
  cart: Cart
  setCurrentPromotion: any
  scrollTarget?: string
  stores: any
}

const MainTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 11,
    padding: theme.spacing(1),
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
}))(Tooltip)

const mapStateToProps = (state) => ({
  cart: state.cart,
  promotions: state.promotions,
  parameters: state.parameters,
  stores: state.stores,
})

const mapDispatchToProps = {
  getPromotions: getPromotions,
  setCurrentPromotion: setCurrentPromotion,
}

const DateRange = ({
  isLanding,
  cart,
  t,
  parameters,
  setCurrentPromotion,
  getPromotions,
  text,
  textFunnel,
  scrollTarget,
  stores,
}: Tprops) => {
  const { deleteCart, createCart, patchDatesCart } = useCart()
  const classes = useStyles()
  const theme = useTheme()
  const router = useRouter()

  const [minStartDay, setMinStartDay] = useState(7)
  const [minDateRange, setMinDateRange] = useState(2)
  const minStartDate = dayjs().add(minStartDay, 'day').toDate()
  const [minEndDate, setMinEndDate] = useState(
    dayjs(minStartDate).add(1, 'day').toDate()
  )
  const [excludedDatesStart, setExcludedDatesStart] = useState([])
  const [excludedDatesEnd, setExcludedDatesEnd] = useState([])
  const [daysOff, setDaysOff] = useState([])
  const startRef = useRef<DatePicker>()
  const endRef = useRef<DatePicker>()
  const [hasReduction, setHasReduction] = useState(false)
  const [reduction, setReduction] = useState(0)
  const [displayTooltip, setDisplayTooltip] = useState(true)
  const [calendarFade, setCalendarFade] = useState(false)
  const [dateLoading, setDateLoading] = useState(false)
  const [dayDuration, setDayDuration] = useState('day')
  const { trackEvent } = useMatomo()
  const dayDurationValues = [
    {
      value: 'day',
      text: text?.fullday,
      defaultText: t('texts:general:fullDay'),
    },
    {
      value: 'morning',
      text: text?.morning,
      defaultText: t('texts:general:morning'),
    },
    {
      value: 'afternoon',
      text: text?.afternoon,
      defaultText: t('texts:general:afternoon'),
    },
    {
      value: 'evening',
      text: text?.evening,
      defaultText: t('texts:general:evening'),
    },
  ]
  const currentLocale = router?.locale ? router.locale : 'fr'

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'))
  const isEnableChooseStartingDate = parameters?.items?.enableChooseStartingDate

  const warehouseId = stores?.selectedStore?.warehouseId
    ? stores?.selectedStore?.warehouseId
    : process.env.CUSTOM_WAREHOUSE
    ? process.env.CUSTOM_WAREHOUSE
    : null

  const handleDateChangeRaw = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    event.preventDefault()
  }

  useEffect(() => {
    // find currently selected duration from cart
    if (cart?.item?.oneDayDuration) {
      setDayDuration(
        dayDurationValues.find(
          (duration) => duration.value === cart?.item?.oneDayDuration
        ).value
      )
    }
  }, [])

  useEffect(() => {
    if (typeof parameters?.items?.minimumStartingDays !== 'undefined') {
      setMinStartDay(parameters?.items?.minimumStartingDays)
    }
    if (typeof parameters?.items?.minimumLeasingDays !== 'undefined') {
      setMinDateRange(parseInt(parameters?.items?.minimumLeasingDays))
      setMinEndDate(
        dayjs()
          .add(parameters?.items?.minimumStartingDays, 'day')
          .add(parameters?.items?.minimumLeasingDays - 1, 'day')
          .toDate()
      )
    }
  }, [parameters.items])

  useEffect(() => {
    calendarFade
      ? document?.body.classList.add('react-datepicker--open')
      : document?.body.classList.remove('react-datepicker--open')
  }, [calendarFade])

  useEffect(() => {
    const daysOffArr = [...daysOff]
    let blockedStart = text?.blocked_dates_start
    let blockedEnd = text?.blocked_dates_end
    if (
      blockedStart !== null &&
      typeof blockedStart !== 'undefined' &&
      blockedEnd !== null &&
      typeof blockedEnd !== 'undefined'
    ) {
      const excludedDates = []
      blockedStart = new Date(blockedStart)
      blockedEnd = new Date(blockedEnd)
      while (blockedStart <= blockedEnd) {
        excludedDates.push(new Date(blockedStart))
        blockedStart.setDate(blockedStart.getDate() + 1)
      }
      excludedDates.map((dates) => {
        daysOffArr.push(dayjs(dates).toDate())
      })

      setDaysOff(excludedDates)
      if (excludedDatesStart.length === 0) {
        setExcludedDatesStart(excludedDates)
      }
      if (excludedDatesEnd.length === 0) {
        setExcludedDatesEnd(excludedDates)
      }
    }

    const fetchDaysOff = async () => {
      let data = {}
      const responseNow = await fetch(
        `${ERequestUrl.apiGouvFr}/${dayjs().year()}.json`
      )
      const responsePlusOne = await fetch(
        `${ERequestUrl.apiGouvFr}/${dayjs().add(1, 'y').year()}.json`
      )
      const dataNow = await responseNow.json()
      const dataPlusOne = await responsePlusOne.json()

      data = { ...dataNow, ...dataPlusOne }
      for (const [key] of Object.entries(data)) {
        daysOffArr.push(dayjs(key).toDate())
      }
      setDaysOff(daysOffArr)
      if (excludedDatesStart.length === 0) {
        setExcludedDatesStart(daysOffArr)
      }
      if (excludedDatesEnd.length === 0) {
        setExcludedDatesEnd(daysOffArr)
      }
    }
    if (process.env.DISABLE_DAYSOFF === 'true') {
      fetchDaysOff()
    }
  }, [excludedDatesStart, excludedDatesEnd])

  const filterDate = (date) => {
    const disableSaturday =
      text?.disable_saturday_on_date_picker && dayjs(date).day() === 6
    const disableSunday =
      text?.disable_sunday_on_date_picker && dayjs(date).day() === 0
    const disableMonday =
      text?.disable_monday_on_date_picker && dayjs(date).day() === 1

    if (disableSaturday || disableSunday || disableMonday) {
      return false
    }

    return true
  }

  const handleOpen = () => {
    setCalendarFade(true)
    hasReduction && setDisplayTooltip(false)
  }

  const handleClose = () => {
    setCalendarFade(false)
    hasReduction && setDisplayTooltip(true)
  }

  const handleSubscriptionDayChange = async (date) => {
    setDateLoading(true)
    trackEvent({
      category: `DatePicker`,
      action: `User select ${dayjs(date).format('DD/MM/YYYY')} as StartDate`,
    })

    if (cart && cart.item) {
      if (cart?.item?.checkoutState === 'cart') {
        const subscriptionDate = dayjs(date).format('DD/MM/YYYY')
        await patchDatesCart({ startRentingDate: subscriptionDate })
      } else {
        // Destroy cart
        await deleteCart(true)
        await createCart(dayjs(date), null, warehouseId)
      }
      setDateLoading(false)
    }
  }

  const handleStartDayChange = async (date) => {
    setDateLoading(true)
    trackEvent({
      category: `DatePicker`,
      action: `User select ${dayjs(date).format('DD/MM/YYYY')} as StartDate`,
    })
    if (cart && cart.item) {
      // If endDate < startdate selected by customer
      const endDatePlusOne = dayjs(date).add(minDateRange - 1, 'day')
      if (dayjs(cart.item.endLeasingDate, 'DD/MM/YYYY') <= dayjs(date)) {
        // Sylius don't accept to patch the dates if cart isn't in cart stage. That mean that if the customer went to shipping et came back to shop to change dates, we have to destroy basket et create a new one
        if (cart?.item?.checkoutState === 'cart') {
          // Keep cart and put dates with selected date as startDate and slected date + 1 day as endDate
          const startDate = dayjs(date).format('DD/MM/YYYY')
          const endDate = dayjs(endDatePlusOne).format('DD/MM/YYYY')
          await patchDatesCart({
            startRentingDate: startDate,
            endRentingDate: endDate,
            oneDayDuration: startDate === endDate ? dayDuration : null,
          })
          setDateLoading(false)
        } else {
          // Destroy cart
          await deleteCart(true)
          await createCart(dayjs(date), dayjs(endDatePlusOne), warehouseId)
          setDateLoading(false)
        }
      } else {
        // endDate > startDate selected by customer
        const dateRange = dayjs(cart.item.endLeasingDate, 'DD/MM/YYYY').diff(
          dayjs(date),
          'day'
        )
        if (dateRange > parameters?.items?.minimumLeasingDays - 1) {
          if (dateRange <= parameters?.items?.maximumLeasingDays) {
            const startDate = dayjs(date).format('DD/MM/YYYY')
            const endDate = cart.item.endLeasingDate
            await patchDatesCart({
              startRentingDate: startDate,
              endRentingDate: endDate,
              oneDayDuration: startDate === endDate ? dayDuration : null,
            })
          } else {
            const startDate = dayjs(date).format('DD/MM/YYYY')
            const endDate = dayjs(date)
              .add(parameters?.items?.maximumLeasingDays - 1, 'day')
              .format('DD/MM/YYYY')
            await patchDatesCart({
              startRentingDate: startDate,
              endRentingDate: endDate,
              oneDayDuration: startDate === endDate ? dayDuration : null,
            })
          }

          setDateLoading(false)
        } else {
          const startDate = dayjs(date).format('DD/MM/YYYY')
          const endDate = dayjs(endDatePlusOne).format('DD/MM/YYYY')
          await patchDatesCart({
            startRentingDate: dayjs(date).format('DD/MM/YYYY'),
            endRentingDate: dayjs(endDatePlusOne).format('DD/MM/YYYY'),
            oneDayDuration: startDate === endDate ? dayDuration : null,
          })
          setDateLoading(false)
        }
        if (cart?.item?.checkoutState === 'cart') {
          // endDate > startDate selected by customer
          // put dates with selected date as startDate and current endDate
        } else {
          // cart state is too advanced, we have to destroy cart and create it back
          await deleteCart(true)
          await createCart(
            dayjs(date),
            dayjs(cart.item.endLeasingDate),
            warehouseId
          )
          setDateLoading(false)
        }
      }
    }
    const excludeDateArray = [...daysOff]
    for (let i = 0; i < minDateRange - 1; i++) {
      excludeDateArray.push(dayjs(date).add(i, 'day').toDate())
    }
    setExcludedDatesEnd(excludeDateArray)
  }

  const handleEndDayChange = async (date) => {
    setDateLoading(true)
    trackEvent({
      category: `DatePicker`,
      action: `User select ${dayjs(date).format('DD/MM/YYYY')} as EndDate`,
    })
    if (cart && cart.item) {
      // If selected endDate is strictly after the startDate
      if (
        dayjs(date).diff(dayjs(cart.item.startLeasingDate, 'DD/MM/YYYY'), 'd') >
        0
      ) {
        if (cart?.item?.checkoutState === 'cart') {
          // If checkoutState is ok, we put dates with current startDate and selected endDate
          const startDate = dayjs(
            cart.item.startLeasingDate,
            'DD/MM/YYYY'
          ).format('DD/MM/YYYY')
          const endDate = dayjs(date).format('DD/MM/YYYY')
          // If checkoutState is ok, we put dates with current startDate and selected endDate
          await patchDatesCart({
            startRentingDate: startDate,
            endRentingDate: endDate,
            oneDayDuration: startDate === endDate ? dayDuration : null,
          })
          setDateLoading(false)
        } else {
          // If cart state is too advanced, we create a new cart
          await deleteCart(true)
          await createCart(
            dayjs(cart.item.startLeasingDate, 'DD/MM/YYYY'),
            dayjs(date),
            warehouseId
          )
          setDateLoading(false)
        }
      } else {
        // If selected endDate is before current startDate
        const startDateMinusOne = dayjs(date).subtract(minDateRange - 1, 'day')
        if (cart?.item?.checkoutState === 'cart') {
          // put dates with startDate as selected date - 1 day and endDate as selected date
          const startDate = dayjs(startDateMinusOne).format('DD/MM/YYYY')
          const endDate = dayjs(date).format('DD/MM/YYYY')
          await patchDatesCart({
            startRentingDate: startDate,
            endRentingDate: endDate,
            oneDayDuration: startDate === endDate ? dayDuration : null,
          })
          setDateLoading(false)
        } else {
          await deleteCart(true)
          await createCart(dayjs(startDateMinusOne), dayjs(date), warehouseId)
          setDateLoading(false)
        }
      }
      const startDateMinusOne = dayjs(date).subtract(minDateRange - 1, 'day')
      const excludeDateArray = [...daysOff]
      for (let i = 0; i < minDateRange - 2; i++) {
        excludeDateArray.push(dayjs(startDateMinusOne).add(i, 'day').toDate())
      }
      setExcludedDatesEnd(excludeDateArray)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    router.pathname === '/' ? router.push('/shop') : scrollToDiv(scrollTarget)
    if (cart?.item?.endLeasingDate && cart?.item?.startLeasingDate) {
      const rentingPeriod =
        dayjs(cart.item.endLeasingDate, 'DD/MM/YYYY').diff(
          dayjs(cart.item.startLeasingDate, 'DD/MM/YYYY'),
          'd'
        ) + 1
      trackEvent({
        category: 'DatePicker',
        href: '/shop',
        action: `User clicked on start button and want to rent for ${rentingPeriod} days`,
      })
    }
  }

  useEffect(() => {
    if (cart?.item) {
      let promotionPercentage = 0
      const start = dayjs(cart.item.startLeasingDate, 'DD/MM/YYYY').format()
      const end = dayjs(cart.item.endLeasingDate, 'DD/MM/YYYY').format()
      const range = dayjs(end).diff(dayjs(start), 'day') + 1
      const calculPromotion = async () => {
        // Get Promotions when one of cart date changes
        const result = await getPromotions()
        if (result.type === 'GET_PROMOTIONS_SUCCESS') {
          if (result?.promotions.length > 0) {
            result?.promotions?.map((promo) => {
              // check if this is only a rules on the dates, otherwise skip
              const checkRule = promo?.rules.find(
                (rule) => rule.type === 'number_of_leasing_day'
              )
              if (typeof checkRule !== 'undefined' && !promo.coupon_based) {
                // Find the right percentage of promotion based on dateRange
                if (
                  range >= promo?.rules[0]?.configuration?.from &&
                  range <= promo?.rules[0]?.configuration?.to
                ) {
                  promotionPercentage =
                    promo.actions.length !== 0
                      ? promo.actions[0].configuration.percentage
                      : 0
                }
              }
            })
            // CHeck if last promotion should be applied for even more day than the range setup
            if (
              range >
                result?.promotions[result?.promotions.length - 1].rules[0]
                  ?.configuration.to &&
              !result?.promotions[result?.promotions.length - 1].coupon_based
            ) {
              promotionPercentage =
                result?.promotions[result?.promotions.length - 1].actions[0]
                  ?.configuration.percentage
            }
            if (promotionPercentage !== 0) {
              setReduction(Math.round(promotionPercentage * 100))
              setCurrentPromotion(Math.round(promotionPercentage * 100))
              setHasReduction(true)
            } else {
              setHasReduction(false)
              setCurrentPromotion(null)
            }
          }
        }
      }
      calculPromotion()
    }
  }, [cart?.item?.startLeasingDate, cart?.item?.endLeasingDate])

  const MyContainer = ({ className, children }) => {
    const hasTextUnderCalendar = isPrismicText(text?.small_text_under_calendar)

    return (
      <div
        className={clsx([
          'datePicker',
          hasTextUnderCalendar && classes.datePickerWithText,
          calendarFade
            ? `datepicker ${classes.show}`
            : `datepicker ${classes.hide}`,
        ])}
      >
        <div className={className}>{children}</div>
        {hasTextUnderCalendar && (
          <div
            className={clsx(classes.backgroundSolid, 'datePicker__background')}
          >
            <div className={clsx(classes.datePickerText, 'datePicker__text')}>
              <CMSText
                asText
                data={text.small_text_under_calendar}
                defaultText={t('texts:general:small_text_under_calendar')}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  const ButtonInput = React.forwardRef<HTMLButtonElement, any>(
    ({ value, onClick, id }, ref) => (
      <Button
        disableRipple
        size="large"
        className={`daterange__btn ${classes.mobileInput}`}
        onClick={onClick}
        ref={ref}
        data-testid={`daterange-${id}`}
      >
        {value === '' ? <Skeleton variant="text" width={'10ch'} /> : value}
      </Button>
    )
  )

  const handleDayDuration = (event) => {
    setDayDuration(event?.target?.value)
    patchDatesCart({
      startRentingDate: cart?.item?.startLeasingDate,
      endRentingDate: cart?.item?.endLeasingDate,
      oneDayDuration: event?.target?.value,
    })
  }

  return (
    <>
      <div
        className={clsx(
          classes.shopLayout,
          isLanding && classes.shopLayoutLanding
        )}
      >
        <form onSubmit={onSubmit} className={classes.root}>
          <div
            className={clsx(
              'daterange__container',
              enableHalfDay && classes.daterangeOneDay
            )}
          >
            <div
              className={clsx(
                'daterange',
                classes.when,
                dateLoading && classes.whenLoading,
                enableHalfDay && classes.whenOneDay
              )}
            >
              {dateLoading && (
                <>
                  <div
                    data-testid="daterange-loading"
                    className={classes.loaderOverlay}
                  >
                    <Loader />
                  </div>
                  <div className={classes.loaderSizer} />
                </>
              )}
              {isEnableChooseStartingDate && isSubscription && (
                <>
                  <DateRangeIcon
                    className={clsx(
                      'daterange__icon',
                      'daterange__icon--calendar',
                      classes.when__icon
                    )}
                  />
                  <DatePicker
                    locale={currentLocale}
                    selected={
                      cart &&
                      cart.item &&
                      !cart?.loading &&
                      cart.item.startLeasingDate !== undefined
                        ? dayjs(
                            cart.item.startLeasingDate,
                            'DD/MM/YYYY'
                          ).toDate()
                        : null
                    }
                    placeholderText={'DD/MM/YYYY'}
                    ref={startRef}
                    filterDate={filterDate}
                    excludeDates={excludedDatesStart}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleSubscriptionDayChange(date)}
                    selectsStart
                    minDate={minStartDate}
                    withPortal={isMobile}
                    name={'startDate'}
                    className={classes.datepicker}
                    calendarContainer={MyContainer}
                    showPopperArrow={false}
                    popperClassName="react-datepicker-popper--no-triangle"
                    popperModifiers={{ offset: { enabled: false } }}
                    onCalendarOpen={handleOpen}
                    onCalendarClose={handleClose}
                    id="start"
                  />
                </>
              )}
              {!isEnableChooseStartingDate && !isFlex && (
                <>
                  <DateRangeIcon
                    className={clsx(
                      'daterange__icon',
                      'daterange__icon--calendar',
                      classes.when__icon
                    )}
                  />
                  <DatePicker
                    locale={currentLocale}
                    selected={
                      cart &&
                      cart.item &&
                      !cart?.loading &&
                      cart.item.startLeasingDate !== undefined
                        ? dayjs(
                            cart.item.startLeasingDate,
                            'DD/MM/YYYY'
                          ).toDate()
                        : null
                    }
                    placeholderText={'DD/MM/YYYY'}
                    ref={startRef}
                    filterDate={filterDate}
                    excludeDates={excludedDatesStart}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleStartDayChange(date)}
                    selectsStart
                    customInput={
                      <ButtonInput
                        value={cart?.item?.startLeasingDate}
                        onClick
                      />
                    }
                    startDate={
                      cart &&
                      cart.item &&
                      cart.item.startLeasingDate !== undefined &&
                      dayjs(cart.item.startLeasingDate, 'DD/MM/YYYY').toDate()
                    }
                    minDate={minStartDate}
                    withPortal={isMobile}
                    name={'startDate'}
                    onChangeRaw={handleDateChangeRaw}
                    endDate={
                      cart &&
                      cart.item &&
                      cart.item.endLeasingDate !== undefined &&
                      dayjs(cart.item.endLeasingDate, 'DD/MM/YYYY').toDate()
                    }
                    className={classes.datepicker}
                    calendarContainer={MyContainer}
                    showPopperArrow={false}
                    popperClassName="react-datepicker-popper--no-triangle"
                    popperModifiers={{ offset: { enabled: false } }}
                    onCalendarOpen={handleOpen}
                    onCalendarClose={handleClose}
                    id="start"
                  />
                  {hasReduction && cart.loading !== true ? (
                    <MainTooltip
                      placement={isDesktop ? 'top' : 'bottom'}
                      PopperProps={{ disablePortal: true }}
                      open={displayTooltip}
                      title={`${prismicText(
                        text?.you_save,
                        t('texts:general:you_save')
                      )} ${reduction.toFixed(0)} %`}
                      arrow
                      enterDelay={2000}
                    >
                      <ArrowRightIcon
                        className={clsx(
                          'daterange__icon',
                          'daterange__icon--arrow',
                          classes.iconArrow
                        )}
                      />
                    </MainTooltip>
                  ) : (
                    <ArrowRightIcon
                      className={clsx(
                        'daterange__icon',
                        'daterange__icon--arrow',
                        classes.iconArrow
                      )}
                    />
                  )}
                  <DatePicker
                    locale={currentLocale}
                    selected={
                      cart &&
                      cart.item &&
                      !cart?.loading &&
                      cart.item.endLeasingDate !== undefined
                        ? dayjs(cart.item.endLeasingDate, 'DD/MM/YYYY').toDate()
                        : null
                    }
                    ref={endRef}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleEndDayChange(date)}
                    onChangeRaw={handleDateChangeRaw}
                    selectsEnd
                    name={'endDate'}
                    withPortal={isMobile}
                    customInput={
                      <ButtonInput value={cart?.item?.endLeasingDate} onClick />
                    }
                    startDate={
                      cart &&
                      cart.item &&
                      cart.item.startLeasingDate !== undefined &&
                      dayjs(cart.item.startLeasingDate, 'DD/MM/YYYY').toDate()
                    }
                    endDate={
                      cart &&
                      cart.item &&
                      cart.item.startLeasingDate !== undefined
                        ? dayjs(cart.item.endLeasingDate, 'DD/MM/YYYY').toDate()
                        : minEndDate
                    }
                    minDate={minEndDate}
                    maxDate={dayjs(cart?.item?.startLeasingDate, 'DD/MM/YYYY')
                      .add(parameters?.items?.maximumLeasingDays - 1, 'day')
                      .toDate()}
                    openToDate={
                      cart &&
                      cart.item &&
                      cart.item.startLeasingDate !== undefined
                        ? dayjs(
                            cart.item.startLeasingDate,
                            'DD/MM/YYYY'
                          ).toDate()
                        : minStartDate
                    }
                    className={classes.datepicker}
                    calendarContainer={MyContainer}
                    filterDate={filterDate}
                    excludeDates={excludedDatesEnd}
                    showPopperArrow={false}
                    popperClassName="react-datepicker-popper--no-triangle"
                    popperModifiers={{ offset: { enabled: false } }}
                    onCalendarOpen={handleOpen}
                    onCalendarClose={handleClose}
                    id="end"
                  />
                </>
              )}
            </div>
            {enableHalfDay &&
              cart?.item?.startLeasingDate === cart?.item?.endLeasingDate && (
                <FormControl variant="outlined" className={classes.formControl}>
                  <select
                    value={dayDuration}
                    onChange={handleDayDuration}
                    className={clsx(
                      'contact_select',
                      classes.dayDurationSelctor
                    )}
                  >
                    {dayDurationValues.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {prismicText(option?.text, option.defaultText)}
                        </option>
                      )
                    })}
                  </select>
                </FormControl>
              )}
          </div>
          <div className={classes.order2}>
            <Button
              disabled={
                !cart?.item?.startLeasingDate ||
                !cart?.item?.endLeasingDate ||
                cart.loading
              }
              data-testid="submitDates"
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              onClick={onSubmit}
              className={clsx('daterange__submit', classes.btnSubmit)}
            >
              {isLanding ? (
                <CMSText
                  asText
                  data={text?.start_button}
                  defaultText={t('texts:general:start_button')}
                />
              ) : (
                <CMSText
                  asText
                  data={text?.confirm_button}
                  defaultText={t('texts:general:confirm_button')}
                />
              )}
            </Button>
          </div>
          <div className={classes.order1}>
            {!isLanding && (
              <ShippingInfos
                startDate={cart?.item?.startLeasingDate}
                endDate={cart?.item?.endLeasingDate}
                deliverDuration={parameters?.items?.deliverDuration}
                returnDuration={parameters?.items?.returnDuration}
                loading={parameters?.loading}
                t={t}
                text={textFunnel}
                isEnableChooseStartingDate={isEnableChooseStartingDate}
              />
            )}
          </div>
        </form>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRange)
